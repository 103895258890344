import Input from "./Input";
import InputMobile from "./InputMobile";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import ActionButton from "./ActionButton";
import Loader from "./Loader";
import DateInput from "./DateInput"
import SearchList from "./SearchList.js"
import Textarea from  "./textArea"
import InputDecmal from  "./decimalInput"
import InputDecimalCustom from "./InputDecimalCustom"
import DateImputMaterialUi from "./DateInputMaterialUi"
const Controls = {
    Input,
    RadioGroup,
    Select, 
    Checkbox, 
    Button,
    ActionButton,
    Loader,
    DateInput,
    SearchList,
    Textarea,
    InputMobile,
    InputDecmal,
    InputDecimalCustom,
    DateImputMaterialUi
}

export default Controls;