import React from 'react'
import { TextField, makeStyles } from '@material-ui/core';
import $ from 'jquery'
const useStyles = makeStyles(theme => ({
    Input: {
        width: "100%",
        "&  .MuiInputBase-input ": {
            padding: "0",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            height: "4rem ",
            [theme.breakpoints.down('lg')]: {
                fontSize: "1.6rem !important",

            },
            [theme.breakpoints.down('md')]: {
                fontSize: "1.4rem !important",

            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.3rem !important",

            },
            //   "& .PrivateNotchedOutline-legendNotched-38":{
            //     maxWidth:"fit-content"
            // }
        },
        "& .MuiInputLabel-formControl": {
            backgroundColor: "white",
            width: "fit-content",
            fontSize: "1.3rem ",
            [theme.breakpoints.down('md')]: {
                fontSize: "1rem ",

            },
            //   [theme.breakpoints.down('sm')]: {
            //     fontSize:"1.3rem !important" ,
            //     paddingBottom:".2rem", 
            //   }
        },


    },
    "qty": {
        "& .MuiInputBase-root  .MuiInputBase-input": {
            textAlign: "right !important"
        },

    },
    "amount": {
        "& .MuiInputBase-root  .MuiInputBase-input": {
            textAlign: "right !important"
        },
    },
    "urdu": {
        "& .MuiInputBase-root  .MuiInputBase-input": {
            textAlign: "right !important"
        },
    }
}))
export default function Input(props) {
    const classes = useStyles();
    const { name, label, value, error = null, onChange, nextid, saveKeyId, listItemsClassName, type = 'text', varient, id, tabindex, height, ...other } = props;
    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            $(`#${nextid}`).focus()
        }
        else if (event.keyCode === 13 && event.keyCode === 18) {
            $(`#${saveKeyId}`).focus()
        }
    }
    return (
        <TextField
            aria-autocomplete="none"
            className={[classes.Input, classes[varient], localStorage.getItem('dodaily_urdu') == "true" ? "UrduFontInputLabel" : ""]}

            variant="outlined"
            label={label}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            autoComplete='false'
            onKeyDown={handleKeyUp}
            id={id}
            {...other}
            {...(error && { error: true, helperText: error })}
            InputLabelProps={{
                shrink: true
            }}
            style={height ? { height: `${height}` } : { height: `inherit` }}
        />
    )
}
