import React, { useEffect,useState } from 'react'
import { Grid, } from '@material-ui/core';
import Controls from "../../components/controls/Controls";
import { useForm, Form } from '../../components/useForm';
import ConfirmDialog from "../../components/ConfirmDialog";



const initialFValues = {
    Area_Name: '',
    Control_Account:""
}

export default function AreaForm(props) {
    const { addOrEdit, recordForEdit } = props
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })
    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('Area_Name' in fieldValues)
            temp.Area_Name = fieldValues.Area_Name ? "" : "Name is not valid"
        if ('Control_Account' in fieldValues)
            temp.Control_Account = fieldValues.Control_Account ? "" : "Control_Account is not valid"
        setErrors({
            ...temp
        })

        if (fieldValues == values)
            return Object.values(temp).every(x => x == "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {

        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            },console.log(initialFValues))
            
            console.log(recordForEdit)
    }, [recordForEdit])

    const onAddOrUdpateArea = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
        handleSubmit()
    }
    
    const onResetForm = () => {
       
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        })
       resetForm()
    }
    return (
        <>
        <Form onSubmit={(e) => {
            e.preventDefault()
            setConfirmDialog({
                isOpen: true,
                title: 'Are you sure to Submit',
                subTitle: "You can't undo this operation",
                onConfirm: () => { onAddOrUdpateArea() }
                            })
                }}>
            <Grid container>
                <Grid item xs={12}>
                    <Controls.Input
                        name="Area_Name"
                        label="Area Name"
                        value={values.Area_Name}
                        onChange={handleInputChange}
                        error={errors.Area_Name}
                    />

                    <Controls.Input
                        name="Control_Account"
                        label="Control Account"
                        value={values.Control_Account}
                        onChange={handleInputChange}
                        error={errors.Control_Account}
                    />

                    

                    <div>
                        <Controls.Button
                        keyupfire={false}
                            type="submit"
                            text="Submit" />
                        <Controls.Button
                        keyupfire={false}
                            text="Reset"
                            color="default"
                            onClick={() => {
                                setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => { onResetForm() }
                                })
                                }} />
                    </div>
                </Grid>
            </Grid>
        </Form>
          <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
      />
      </>
    )
}
