import React, { useEffect } from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BorderColor } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(0),
      width:"100%",
      color:"#6eba3e",
      borderColor:"#6eba3e",
      "& .MuiInputBase-root":{
      height:"4rem",
      },
      "& .MuiFormLabel-root":{

        textAlign:"center",
        backgroundColor:"#ffffff",
        fontSize:"1.3rem " ,
      [theme.breakpoints.down('md')]: {
          fontSize:"1rem " ,
      
        },
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));
  

export default function Select(props) {
    const classes = useStyles();
    const { name, label, value,error=null, onChange, options } = props;
//    const renameArray=(object)=>{
//     for (let [key, value] of Object.entries(object)) {
//         key="ID"
//       }
//       console.log(object)
//     }
//     useEffect(()=>{
//         renameArray(options)
//     },[options])
    return (
        <FormControl  className={classes.formControl} variant="outlined"
        {...(error && {error:true})}>
            <InputLabel className={localStorage.getItem('dodaily_urdu') == "true"?"UrduFontInputLabel":""} style={{backgroundColor:"white",paddingBottom:".5rem"}} shrink={true} >{label}</InputLabel>
            <MuiSelect
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                >
                {
                    options?(options.map(
                        item => {
                            const propertyValues = Object.values(item);
                                return <MenuItem  key={propertyValues[0]} id={propertyValues[0]} value={propertyValues[0]}>{propertyValues[1]}</MenuItem>
                            }
                    )):(null)
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
