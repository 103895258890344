import React, { useEffect, useState } from 'react'
import { Table, TableHead, TableRow, TableCell, makeStyles, TablePagination, TableSortLabel } from '@material-ui/core'
import "../pages/Customers/pages.css"

const useStyles = makeStyles(theme => ({
    paginationDiv: {
        "& .MuiIconButton-root": {
            color: theme.palette.primary.text,
        },
        "& .Mui-disabled": {
            color: "#dadada !important",
        },
        "& .MuiTypography-root": {
            color: theme.palette.primary.text,
            [theme.breakpoints.down('lg')]: {
                fontSize: "1.3rem !important"
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "1.2rem !important"
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "1rem !important"
            }
        },
        "& .MuiSelect-select": {
            color: theme.palette.primary.text,
            [theme.breakpoints.down('lg')]: {
                fontSize: "1.3rem !important"
            },
            [theme.breakpoints.down('md')]: {
                fontSize: "1.2rem !important"
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "1rem !important"
            }
        }
    },
    format: {
        [theme.breakpoints.up(900)]: {
            "& [name=date] , [name=contactNumber] , [name=id] , [name=qty] ": {
                textAlign: "center !important"
            },
            "& [name=amount]": {
                textAlign: "right !important"
            },
            "& [name=totalamount]": {
                textAlign: "right !important"
            }
        },
        "& .MuiTableCell-stickyHeader": {

            [theme.breakpoints.up(900)]: {
                backgroundColor: "inherit",
                paddingTop: ".5rem",
            },
            "& .MuiTableSortLabel-root": {
                flexDirection: "column",

            }
        }
    }
}))

export default function useTable(records, headCells, filterFn, ref, breakOn = 'medium', totalPages, currentPage, totalItems, getallitems, EmptyItems, useUseEffect, setLoading, dependenceies = [], getFilteredOrSearchItems, SearchOrDependenceies = [true]) {

    const classes = useStyles();
    let tableClass = 'table-container__table';
    const pages = [3, 5, 10, 25]
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20)
    const [order, setOrder] = useState()
    const [orderBy, setOrderBy] = useState()
    const handleSortRequest = cellId => {
        const isAsc = orderBy === cellId && order === "desc";
        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(cellId)
    }
    if (breakOn === 'small') {
        tableClass += ' table-container__table--break-sm';
    } else if (breakOn === 'medium') {
        tableClass += ' table-container__table--break-md';
    } else if (breakOn === 'large') {
        tableClass += ' table-container__table--break-lg';
    }
    const TblContainer = props => (

        <Table stickyHeader
            className={tableClass + " " + classes.format}
        >
            {props.children}
        </Table>
    )

    let styles = {
        // fontWeight: "bold",
        padding: 1,
        textAlign: "center",
        verticalAlign: "middle",
        fontSize: 14,
        color: "white",
        fontWeight: "500",
        backgroundColor: "#5B2B17"
    }

    const TblHeadforcustomer = props => {
        // console.log(props)
        let headCellss = [
            { id: 'Customer_Sort_Number', label: 'Sort No.', name: 'Sort No.' },
            { id: 'Customer_Name', label: 'Customer', name: 'name' },
            { id: 'Customer_Mobile', label: 'Mobile', name: 'mobile' },
            { id: 'Customer_Morning_Milk_QTY', label: 'Morning Milk', name: 'qty' },
            { id: 'Customer_Morning_yog_QTY', label: 'Morning Yogurt', name: 'qty' },
            { id: 'Customer_Morning_Ghee_QTY', label: 'Morning Others', name: 'qty' },
            { id: 'Customer_Evening_Milk_QTY', label: 'Evening Milk', name: 'qty' },
            { id: 'Customer_Evening_yog_QTY', label: 'Evening Yogurt', name: 'qty' },
            { id: 'Customer_Evening_Ghee_QTY', label: 'Evening Others', name: 'qty' },
            { id: 'Customer_Milk_Rate', label: 'Milk Rate', name: 'rate' },
            { id: 'Customer_Yog_Rate', label: 'Yogurt Rate', name: 'rate' },
            { id: 'Customer_Ghee_Rate', label: 'Others Rate', name: 'rate' },
            { id: 'Customer_Active', label: 'Active', name: 'active' },
            { id: 'actions', label: 'Action', name: 'actions' }
        ]
        return (<>

            <thead
                // className="table table-bordered" id="table-main"
                style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
                {/* <tr>
                    <td style={{ ...styles }} rowspan="2">S. No</td>
                    <td style={{ ...styles }} rowspan="2">Customer Name</td>
                    <td style={{ ...styles }} rowspan="2">Mobile</td>
                    <td style={{ ...styles }} colspan="3">Morning</td>
                    <td style={{ ...styles }} colspan="3">Evening</td>
                    <td style={{ ...styles }} colspan="3">Rate</td>
                    <td style={{ ...styles }} rowspan="2">Active</td>
                    <td style={{ ...styles }} rowspan="2">Action</td>
                </tr>
                <tr>
                    <td style={{ ...styles }}>Milk</td>
                    <td style={{ ...styles }}>Yogurt</td>
                    <td style={{ ...styles }}>Others</td>
                    <td style={{ ...styles }}>Milk</td>
                    <td style={{ ...styles }}>Yogurt</td>
                    <td style={{ ...styles }}>Others</td>
                    <td style={{ ...styles }}>Milk</td>
                    <td style={{ ...styles }}>Yogurt</td>
                    <td style={{ ...styles }}>Others</td>
                </tr> */}
                <tr>
                    {
                        headCellss.map(headCell => (
                            <td name={headCell.name} key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ ...styles, color: "white", backgroundColor: "#5b2b17", textAlign: props.align }}
                            >
                                {
                                    headCell.disableSorting ? <>
                                        {headCell.label}
                                    </> :
                                        <>
                                            {
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={() => { handleSortRequest(headCell.id) }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {headCell.label}
                                                </TableSortLabel>
                                            }
                                        </>
                                }
                            </td>
                        ))
                    }
                </tr>
            </thead>

            {/* <TableHead>
                <TableRow>
                    {
                        headCellss.map(headCell => (
                            <TableCell name={headCell.name} key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {
                                    headCell.disableSorting ? <>
                                        {headCell.label}
                                    </> :
                                        <>
                                            {
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={() => { handleSortRequest(headCell.id) }}
                                                >
                                                    {headCell.label}
                                                </TableSortLabel>
                                            }
                                        </>
                                }
                            </TableCell>))
                    }
                </TableRow>
            </TableHead > */}
        </>
        )
    }

    const TblHead = props => {
        // console.log(headCells)
        return (<>

            <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
                <tr>
                    {
                        headCells.map((headCell, i) => (

                            <td name={headCell.name} key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                                style={{ ...styles, color: "white", backgroundColor: "#5b2b17", textAlign: props.align }}
                            >
                                {headCell.disableSorting ? <> {headCell.label} </> :
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'desc'}
                                        onClick={() => { handleSortRequest(headCell.id) }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                }
                            </td>))
                    }
                </tr>
            </thead>

            {/* <TableHead>
                <TableRow>
                    {
                        headCells.map(headCell => (
                            <TableCell name={headCell.name} key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}>
                                {headCell.disableSorting ? <> {headCell.label} </> :
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'desc'}
                                        onClick={() => { handleSortRequest(headCell.id) }}>
                                        {headCell.label}
                                    </TableSortLabel>
                                }
                            </TableCell>))
                    }
                </TableRow>
            </TableHead> */}

        </>
        )
    }

    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        // console.log("scrollTop" + scrollTop)
        // console.log("clientHeight" + clientHeight)
        // console.log("scrollHeight" + scrollHeight)
        if (scrollHeight - scrollTop === clientHeight && page < totalPages) {
            setPage(page + 1);
            // console.log(page)
        }
    };


    useEffect(() => {
        if (useUseEffect === false) {
            return null
        } else if (page > 0 && page < totalPages && records.length < totalItems) {
            if (SearchOrDependenceies[0]) {
                getallitems({ page: page, limit: rowsPerPage }, setLoading)
            } else {
                getFilteredOrSearchItems(page, rowsPerPage)
            }
            // console.log("useTable page use effact page =" + page)
            setLoading(true)
        }
        else if (page > totalPages && records.length > totalItems) {
            setLoading(false)
        }
    }, [page])
    useEffect(() => {
        if (useUseEffect === false) {
            return null
        }
        else {
            EmptyItems()
            getallitems({ page: page, limit: rowsPerPage }, setLoading)
            setLoading(true)
        }

    }, [...dependenceies])




    const TblPagination = () => (<TablePagination
        className={classes.paginationDiv}
        component="div"
        page={page}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={totalItems}
    />)

    function stableSort(array, comparator) {
        const stabilizedThis = array
            .sort((a, b) => b.Customer_Sort_Number - a.Customer_Sort_Number)
            .map((el, index) => [el, index])
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order
            return b[1] - a[1]
        });
        return stabilizedThis.map((el) => el[0])
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)
    }

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const recordsAfterPagingAndSorting = () => (
        stableSort(filterFn.fn(records), getComparator(order, orderBy))

    )

    return {
        TblContainer,
        TblHead,
        TblPagination,
        recordsAfterPagingAndSorting,
        rowsPerPage,
        page,
        setPage,
        handleScroll,
        handleSortRequest,
        setOrder,
        TblHeadforcustomer
    }
}
