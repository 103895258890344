import React from 'react'
import { Paper, Card, Typography, makeStyles,Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
         padding: theme.spacing(0,3,0,3),
        backgroundColor: '#ffffff',
        width:"100%",
        display:"flex",
        alignItems:"center",
        marginBottom:"1rem",
        // paddingRight:"2rem",

        // [theme.breakpoints.down(600)]:{
        //     paddingLeft:".5rem",
        //     paddingTop:"1rem",
        //     paddingRight:".5rem",
        // },
    //     "& .MuiGrid-item > *":{
    //         maxWidth: `calc(100% - 1rem) !important`,
    //         width: `calc(100% - 1rem) !important`,
    //         marginBottom:`1rem !important`,
    //         marginRight: `1rem !important`
    //    },
       
    },
    pageHeader:{
  
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        // overflow:"hidden"

    },
    pageIcon:{
        display:'inline-block',
        color:theme.palette.primary.main,
        // padding: ".5rem"
    },
    pageInnerContainer:{
        display:"flex",
        alignItems:"center",
        "& svg":{
            fontSize:"3rem"
        },
        
    },
    normalHeight:{
        height:"8rem"
    },
    maxHeight:{
        height:"8rem",
        [theme.breakpoints.down("sm")]:{
            height:"12rem"
        } 
    },

    pageTitle:{

        '& .MuiTypography-subtitle2':{
            opacity:'0.6'
        },
        "& .MuiTypography-h6":{
            fontSize:"2rem"
        }
        
    },
    mainTeading:{
        color:theme.palette.primary.text
    }
}))

export default function PageHeader({ height="normalHeight",title, subTitle, icon,children,divWidthlg=3,divWidthmd=3,divWidthsm=2,divWidthxs=0,titleWidthlg=3,titleWidthmd=3,titleWidthsm=2,titleWidthxs=0    }) {

    const classes = useStyles();
   
    return (
        <Paper   className={`${classes.root} ${classes[height]} pageHeader`}>
            <Grid container className={classes.pageHeader} spacing={2}>
                <Grid item className={`${classes.pageInnerContainer} pageHeaderPageInfo`}  lg={divWidthlg} md={divWidthmd} sm={divWidthsm} xs={divWidthxs}>
                <Grid item  lg={2} md={2} sm={2}>
                    <Card className={classes.pageIcon}>
                    {icon}
                    </Card>
                    </Grid>
                    <Grid container   lg={titleWidthlg} md={titleWidthmd} sm={titleWidthsm}>
                <div className={classes.pageTitle}>
                    <Typography
                    className={classes.mainTeading}
                        variant="h6"
                        component="div">
                        {title}</Typography>
                    <Typography  
                        variant="subtitle2"
                        component="div">
                        {subTitle}</Typography>
                        </div>
                        </Grid>
                </Grid>
                {children}
            </Grid>
        </Paper>
    )
}
